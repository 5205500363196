<template>
  <div class="homePage">
    <Loader v-if="loading" />
    <div v-else>
      <NoData v-if="noData" />
      <div class="" ref="main_wrapper" v-else>
        <div class="row fadeIn">
          <!-- End:: Aside -->
          <the-filter :inputs="inputs" />
          <div class="col-md-12 col-xl-12 py-0">
            <main>
              <!-- <base-card classes="overflow-auto">
              <div class="mb-2" v-for="row in rows" :key="row.id">
                <organization-chart
                  dir="ltr"
                  @node-click="getNode"
                  :datasource="row"
                >
                  <template slot-scope="{ nodeData }">
                    <h5 class="node_title">
                      {{ nodeData[$i18n.locale].name }}
                    </h5>
                  </template>
                </organization-chart>
              </div>
            </base-card> -->
              <v-data-table
                class="thumb strip"
                :headers="headers"
                :items="accounts"
                :loading="loading"
                :loading-text="$t('table.loadingData')"
                item-key="id"
                :items-per-page="paginations.items_per_page"
                hide-default-footer
              >
                <!-- ================== You Can use any slots you want ================== -->

                <template v-slot:[`item.index`]="{ index }">
                  {{ index + 1 }}
                </template>

                <template v-slot:[`item.behavior`]="{ item }">
                  <span>{{ $t(`behaviors.${item.behavior}`) }}</span>
                </template>

                <!-- Select no data State -->
                <template v-slot:no-data>
                  {{ $t("table.noData") }}
                </template>

                <!-- IsActive -->
                <template v-slot:[`item.has_depreciation`]="{ item }">
                  <span>{{
                    item.has_depreciation
                      ? $t("depreciable")
                      : $t("notDepreciable")
                  }}</span>
                </template>

                <template v-slot:[`item.opening_balance`]="{ item }">
                  <span v-if="item.opening_balance">{{
                    item.opening_balance
                  }}</span>
                  <span class="redColor fontBold" v-else>{{
                    $t("notFound")
                  }}</span>
                </template>
                <template v-slot:[`item.balance`]="{ item }">
                  <span v-if="item.balance">{{ item.balance }}</span>
                  <span class="redColor fontBold" v-else>{{
                    $t("notFound")
                  }}</span>
                </template>

                <!-- Select actions-->
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="_actions">
                    <v-icon
                      class="show"
                      v-if="!canShow"
                      small
                      @click="showItem(item)"
                    >
                      fal fa-eye
                    </v-icon>
                    <v-icon
                      class="show"
                      v-if="!canEdit"
                      small
                      @click="editItem(item)"
                    >
                      fal fa-edit
                    </v-icon>
                    <deleter
                      v-if="!canDelete"
                      :items="rows"
                      name="accounts"
                      :id="item.id"
                      @refresh="rows = $event"
                    />
                  </div>
                </template>

                <!-- ======================== Start Top Section ======================== -->
                <template v-slot:top>
                  <h3 class="table-title title">
                    {{ $t("breadcrumb.accounts.title") }}
                    <span class="total">({{ accounts.length }})</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          far fa-info-circle
                        </v-icon>
                      </template>
                      <span> {{ $t("breadcrumb.companies.all") }}</span>
                    </v-tooltip>
                  </h3>
                </template>
                <!-- ======================== End Top Section ======================== -->
              </v-data-table>
              <!-- Start Pagination -->
              <!-- <template>
                <div
                  class="pagination_container text-center mb-5 d-flex justify-content-end"
                >
                  <v-pagination
                    color="primary"
                    v-model="paginations.current_page"
                    :length="paginations.last_page"
                    :total-visible="5"
                    @input="fetchData($event)"
                  ></v-pagination>
                </div>
              </template> -->
              <!-- End Pagination -->
            </main>
            <main>
              <v-data-table
                class="thumb strip"
                :headers="headers2"
                :items="transactions"
                :loading="loading"
                :loading-text="$t('table.loadingData')"
                item-key="id"
                :items-per-page="paginations.items_per_page"
                hide-default-footer
              >
                <!-- ================== You Can use any slots you want ================== -->
                <!-- ====== Select row field ====== -->

                <template v-slot:[`item.index`]="{ index }">
                  {{ index + 1 }}
                </template>

                <template v-slot:[`item.sender`]="{ item }">
                  <router-link
                    v-if="item.sender"
                    :to="`/accounts/show/${item.sender.id}`"
                  >
                    <span>
                      {{ item.sender[$i18n.locale].name }}
                    </span>
                  </router-link>
                  <span class="redColor fontBold" v-else>{{
                    $t("notFound")
                  }}</span>
                </template>
                <template v-slot:[`item.receiver`]="{ item }">
                  <router-link
                    v-if="item.receiver"
                    :to="`/accounts/show/${item.receiver.id}`"
                  >
                    <span>
                      {{ item.receiver[$i18n.locale].name }}
                    </span>
                  </router-link>
                  <span class="redColor fontBold" v-else>{{
                    $t("notFound")
                  }}</span>
                </template>
                <template v-slot:[`item.note`]="{ item }">
                  <div class="_actions" v-if="item.note">
                    <v-icon
                      class="show"
                      small
                      @click="show_model_1(item.note, `ltr`)"
                    >
                      fas fa-eye
                    </v-icon>
                  </div>

                  <span class="redColor fontBold" v-else>{{
                    $t("notFound")
                  }}</span>
                </template>

                <!-- Select no data State -->
                <template v-slot:no-data>
                  {{ $t("table.noData") }}
                </template>

                <!-- Select actions-->
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="_actions">
                    <v-icon
                      class="edit"
                      v-if="!canEdit"
                      small
                      @click="editItem(item)"
                    >
                      fal fa-edit
                    </v-icon>
                    <deleter
                      v-if="!canDelete"
                      :items="rows"
                      name="cancel-reason"
                      :id="item.id"
                      @refresh="rows = $event"
                    />
                  </div>
                </template>

                <!-- ======================== Start Top Section ======================== -->
                <template v-slot:top>
                  <h3 class="table-title title">
                    {{ $t("breadcrumb.transfers.title") }}

                    <span class="total">({{ transactions.length }})</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          far fa-info-circle
                        </v-icon>
                      </template>
                      <span>{{ $t("breadcrumb.transfers.all") }}</span>
                    </v-tooltip>
                  </h3>
                  <!-- Delete dialog -->

                  <!-- Add & Delete -->
                  <v-row>
                    <v-col cols="12" sm="8"></v-col>
                  </v-row>
                </template>
                <!-- ======================== End Top Section ======================== -->
              </v-data-table>
              <!-- Start Pagination -->
              <!-- <template>
                <div
                  class="pagination_container text-center mb-5 d-flex justify-content-end"
                >
                  <v-pagination
                    color="primary"
                    v-model="paginations.current_page"
                    :length="paginations.last_page"
                    :total-visible="5"
                    @input="fetchData($event)"
                  ></v-pagination>
                </div>
              </template> -->
              <!-- End Pagination -->
            </main>
          </div>
          <!-- Start:: Aside -->
          <div class="col-md-5 col-xl-3 py-0" v-if="false">
            <simple-card classes="w-100">
              <div class="notifications_states">
                <header>
                  <h2 class="title">
                    {{ $t("labels.notifications") }}
                  </h2>
                </header>
                <div class="content">
                  <div class="notification_card">
                    <div v-if="notifications_states.length">
                      <router-link
                        class="state"
                        v-for="notfy in notifications_states"
                        :key="notfy.id"
                        :to="`/${getRouteLink(notfy)}/show/${notfy.data_id}`"
                      >
                        <div class="notification_content">
                          <h5 class="title">
                            {{ notfy.title[$i18n.locale] }}
                          </h5>
                          <p :title="notfy.body[$i18n.locale]">
                            {{ notfy.body[$i18n.locale].slice(0, 25)
                            }}{{
                              notfy.body[$i18n.locale].length > 25 ? "..." : ""
                            }}
                          </p>
                        </div>
                      </router-link>
                    </div>
                    <div v-else class="text-center no-data">
                      <img
                        src="@/assets/media/images/Others/no_notifications.svg"
                        alt="No Notifications"
                        width="100"
                      />
                      <h4>{{ $t("navbar.notification.no_notifications") }}</h4>
                    </div>
                  </div>
                </div>
                <footer v-if="notifications_states.length">
                  <router-link to="/notifications/show-all">{{
                    $t("buttons.seeMore")
                  }}</router-link>
                </footer>
              </div>
            </simple-card>
            <simple-card classes="w-100">
              <div class="notifications_states client">
                <header>
                  <h2 class="title">
                    {{ $t("labels.topRiders") }}
                  </h2>
                </header>
                <div class="content">
                  <div class="notification_card">
                    <div v-if="recently_rider.length">
                      <div
                        class="state"
                        v-for="item in recently_rider"
                        :key="item.id"
                      >
                        <div class="icon">
                          <img
                            width="55"
                            height="55"
                            class="rounded-lg"
                            :src="item.avatar"
                            alt=""
                          />
                        </div>
                        <div class="notification_content">
                          <router-link
                            :to="`/riders/show/${item.id}`"
                            class="title"
                            >{{ item.full_name }}</router-link
                          >
                          <a :href="`tel:${item.phone_complete_form}`">
                            <bdi> {{ item.phone_complete_form }}</bdi>
                          </a>
                        </div>
                      </div>
                    </div>
                    <span
                      class="redColor mt-4 fs-6 text-center d-block fontBold"
                      v-else
                    >
                      {{ $t("notFound") }}</span
                    >
                  </div>
                </div>
                <footer>
                  <router-link to="/riders/show-all">{{
                    $t("breadcrumb.riders.all")
                  }}</router-link>
                </footer>
              </div>
            </simple-card>
            <simple-card classes="w-100">
              <div class="notifications_states client">
                <header>
                  <h2 class="title">
                    {{ $t("labels.topPartners") }}
                  </h2>
                </header>
                <div class="content">
                  <div class="notification_card">
                    <div v-if="recently_partner.length">
                      <div
                        class="state"
                        v-for="item in recently_partner"
                        :key="item.id"
                      >
                        <div class="icon">
                          <img
                            width="55"
                            height="55"
                            class="rounded-lg"
                            :src="item.avatar"
                            alt=""
                          />
                        </div>
                        <div class="notification_content">
                          <router-link
                            :to="`/partners/show/${item.id}`"
                            class="title"
                            >{{ item.full_name }}</router-link
                          >
                          <a :href="`tel:${item.phone_complete_form}`">
                            <bdi> {{ item.phone_complete_form }}</bdi>
                          </a>
                        </div>
                      </div>
                    </div>
                    <span
                      class="redColor mt-4 fs-6 text-center d-block fontBold"
                      v-else
                    >
                      {{ $t("notFound") }}</span
                    >
                  </div>
                </div>
                <footer>
                  <router-link to="/partners/show-all">{{
                    $t("breadcrumb.partners.all")
                  }}</router-link>
                </footer>
              </div>
            </simple-card>
            <simple-card classes="w-100">
              <div class="notifications_states client">
                <header>
                  <h2 class="title">
                    {{ $t("labels.topPackages") }}
                  </h2>
                </header>
                <div class="content">
                  <div class="notification_card">
                    <div v-if="top_vehicle_package.length">
                      <div
                        v-for="item in top_vehicle_package"
                        :key="item.id"
                        class="align-items-start border-bottom pb-1 state"
                      >
                        <div class="icon">
                          <img
                            width="55"
                            height="55"
                            class="rounded-lg"
                            :src="item.image"
                            alt=""
                          />
                        </div>
                        <div class="notification_content">
                          <h5 class="title">{{ item[$i18n.locale].title }}</h5>
                          <p>
                            <bdi>
                              {{ $t("labels.base_fare") }}
                            </bdi>
                            <bdi>
                              <span>{{ item.base_fare }}</span>
                            </bdi>
                            <bdi> {{ $t("appCurrency") }} </bdi>
                          </p>
                          <p>
                            <bdi>
                              {{ $t("labels.minimum_fare") }}
                            </bdi>
                            <bdi>
                              <span>{{ item.minimum_fare }}</span>
                            </bdi>
                            <bdi> {{ $t("appCurrency") }} </bdi>
                          </p>
                          <p>
                            <bdi> {{ $t("labels.price_per_kilo") }} :</bdi>
                            <bdi>
                              <span>{{ item.price_per_kilo }}</span></bdi
                            >
                            <bdi> {{ $t("appCurrency") }} </bdi>
                          </p>
                          <p>
                            <bdi> {{ $t("labels.price_per_minute") }} :</bdi>
                            <bdi
                              ><span>{{ item.price_per_minute }}</span></bdi
                            >
                            <bdi> {{ $t("appCurrency") }} </bdi>
                          </p>
                        </div>
                      </div>
                    </div>
                    <span
                      class="redColor mt-4 fs-6 text-center d-block fontBold"
                      v-else
                    >
                      {{ $t("notFound") }}</span
                    >
                  </div>
                </div>
                <footer>
                  <router-link to="/vehicle_packages/show-all"
                    >{{ $t("breadcrumb.vehicle_packages.all") }}
                  </router-link>
                </footer>
              </div>
            </simple-card>
          </div>
        </div>
        <base-model
          @close="model_1.show_model = false"
          :show="model_1.show_model"
        >
          <div :dir="direction">
            <p class="with_border" v-html="model_1.model_img_src"></p>
          </div>
        </base-model>
      </div>
    </div>
  </div>
</template>

<script>
import ChartsAside from "@/components/Layouts/ChartsAside.vue";
import Loader from "@/components/Loaders/loader.vue";

import AreaCharts from "@/components/Charts/AreaCharts.vue";
import ColumnChart from "@/components/Charts/ColumnChart.vue";

export default {
  name: "HomePage",

  components: {
    ChartsAside,
    AreaCharts,
    ColumnChart,
    Loader,
  },

  data() {
    return {
      colors: [
        {
          first: "#62EAE1",
          secound: "rgba(126, 229, 223, 0)",
        },
        {
          first: "#FF6370",
          secound: "rgba(255, 99, 112, 0)",
        },
        {
          first: "#9E62EA",
          secound: " #B199ED",
        },
        {
          first: "#EABC62",
          secound: "#E9BA62",
        },
      ],
      inputs: [
        {
          keyName: "transaction_search",
        },
        {
          keyName: "account_search",
        },
      ],
      // ========== Charts Aside
      asideIsActive: false,
      // start of main loader
      noData: true,
      loading: false,
      // end of main loader
      // ============ Charts Data
      cards: [],
      notifications_states: [],
      charts: [],
      top_cards: [],
      recently_partner: [],
      recently_rider: [],
      top_vehicle_package: [],
      transactions: [],
      accounts: [],
      chartsData: null,

      headers: [
        {
          text: "#",
          align: "center",
          value: "index",
          sortable: false,
        },

        {
          text: this.$t("labels.name"),
          value: `${this.$i18n.locale}.name`,
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.behavior"),
          value: "behavior",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.has_depreciation"),
          value: "has_depreciation",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.opening_balance"),
          value: "opening_balance",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.balance"),
          value: "balance",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.created_at"),
          align: "center",
          value: "created_at",
          sortable: false,
        },

        // {
        //   text: this.$t("labels.control"),
        //   value: "actions",
        //   align: "center",
        //   sortable: false,
        // },
      ],

      headers2: [
        {
          text: "#",
          value: "index",
          align: "center",
        },
        {
          text: this.$t("labels.sender"),
          value: "sender",
          align: "center",
        },
        {
          text: this.$t("labels.receiver"),
          value: "receiver",
          align: "center",
        },

        {
          text: this.$t("labels.note"),
          value: "note",
          align: "center",
        },

        // {
        //   text: this.$t("labels.control"),
        //   value: "actions",
        //   align: "center",
        //   sortable: false,
        // },
      ],
      direction: null,
    };
  },
  watch: {
    [`$route`]() {
      this.getHome();
    },
  },

  methods: {
    toggleAside() {
      this.asideIsActive = !this.asideIsActive;
    },
    getRouteLink(item) {
      let link;
      if (item.notify_type == "new_trip") {
        link = "trips";
      } else if (item.notify_type == "new_rider") {
        link = "riders";
      } else if (item.notify_type == "new_partner") {
        link = "partners";
      } else if (item.notify_type == "finish_trip") {
        link = "trips";
      }

      return link;
    },
    show_model_1(src, dir) {
      this.model_1.model_img_src = src;
      this.model_1.show_model = true;
      this.direction = dir;
    },
    getHome() {
      this.loading = true;
      this.noData = false;

      this.axios({
        method: "GET",
        url: process.env.VUE_APP_BASE_GENERAL + "/general/lastStatics",
        params: {
          account_search: this.$route.query.account_search || "",
          transaction_search: this.$route.query.transaction_search || "",
        },
      })
        .then((res) => {
          this.noData = false;
          // this.cards = res.data.data.cards;
          // this.notifications_states = res.data.data.notifications;
          // this.top_cards = res.data.data.top_cards;
          // this.recently_partner = res.data.data.recently_partner;
          // this.recently_rider = res.data.data.recently_rider;
          // this.top_vehicle_package = res.data.data.top_vehicle_package;
          this.accounts = res.data.data.accounts;
          this.transactions = res.data.data.transactions;
          // this.charts = res.data.data.charts;
          // this.chartsData = {
          //   charts: res.data.data.side_charts,
          // };
          this.loading = false;
        })
        .catch((err) => {
          this.noData = true;
          this.loading = false;
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },

    getRandomColor(colors) {
      let random = Math.floor(Math.random() * 4);

      return { colors: colors[random], id: random };
    },
    imageError(index, inp) {
      this[inp][
        index
      ].avatar = require("@/assets/media/images/Icons/no-data.gif");
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.getHome();
  },
};
</script>

<style lang="scss">
.stats_chart {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  // justify-content: space-c;
  padding: 15px;
  // flex-direction: row-reverse;
  .icon {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-end: 1rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    .value {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      color: #000;
    }
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 8px;
      color: var(--mainText);
    }
  }
}
.notifications_states {
  header {
    border-bottom: 1px solid #efefef !important;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px !important;
      line-height: 20px;
      color: #000;
    }
  }
  .content {
    min-height: 200px;
    max-height: 250px;
    overflow-y: auto;
    padding-block: 8px;
    .notification_card {
      .state {
        display: flex;
        align-items: center;
        .notification_content {
          margin-inline-start: 8px;
          .title {
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 17px;
            color: #000;
            margin: 0;
            margin-bottom: 5px;
          }
          p,
          a {
            font-style: normal;
            font-weight: 400 !important;
            font-size: 10px !important;
            line-height: 15px;
            color: var(--mainText);
            margin: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h4 {
          font-size: 18px !important;
        }
      }
    }
  }
  footer {
    text-align: center;
    border-top: 1px solid #efefef !important;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--mainText);
    }
  }

  &.client {
    .content {
      min-height: 250px;
      max-height: 250px;
    }
  }
}
</style>
